<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách ca làm việc '">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission(permission.create)">
          <b-button
            @click="handelBtnCreate"
            v-b-modal.modal-setting-shiftWork
            variant="primary"
            size="sm"
          >
            <strong><i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới</strong>
          </b-button> 
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-7">
          <b-col>
            <b-form-input
              placeholder="Tìm kiếm theo tên hoăc id ca làm việc"
              v-model="textSearch"
              type="text"
              debounce="1000"
            ></b-form-input
          ></b-col>
          <b-col></b-col>
        </b-row>
        <div class="mt-3">
          <b-table
            :items="listShiftWork"
            :fields="fields"
            striped
            responsive="sm"
            class="table-bordered table-hover"
            no-local-sorting
            :busy="onLoading"
          >
            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item v-if="checkPermission(permission.edit)"
                    v-b-modal.modal-setting-shiftWork
                    @click="editItem(row.item)"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon2-pen"></i>
                      &nbsp; Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="checkPermission(permission.delete)" @click="showDeleteAlert(row.item)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
        <b-modal
          ref="modal-setting-shiftWork"
          id="modal-setting-shiftWork"
          size="md"
          :title="modalTitle"
          @hidden="onReset"
          hide-footer
        >
          <b-container>
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                id="input-group-1"
                label="Tên ca làm việc"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Nhập tên ca làm việc"
                  required
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group label="Chọn giờ bắt đầu ca:">
                    <b-time v-model="form.fromHour"></b-time
                  ></b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Chọn giờ kết thúc ca:">
                    <b-time v-model="form.toHour"></b-time
                  ></b-form-group>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mt-5">
                <b-button v-if="checkPermission(permission.edit)" class="mr-3" type="submit" variant="primary"
                  >Lưu</b-button
                >
                <b-button type="reset" variant="danger">Hủy</b-button></b-row
              >
            </b-form>
          </b-container>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import Swal from 'sweetalert2';
import localData from '@/utils/saveDataToLocal';

export default {
  data() {
    return {
      onLoading: false,
      listShiftWork: [],
      action: '',
      modalTitle: '',
      textSearch: '',
      permission: {
        viewList: 'SHIFTWORK_VIEW',
        delete: 'SHIFTWORK_DELETE',
        create: 'SHIFTWORK_INSERT',
        edit: 'SHIFTWORK_UPDATE',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên ca làm việc',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'fromHour',
          label: 'Giờ bắt đầu',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'toHour',
          label: 'Giờ kết thúc',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'actionProClass',
          thClass: 'actionProClass',
        },
      ],
      form: {
        name: '',
        fromHour: '',
        toHour: '',
      },
    };
  },
  created() {
    this.fetchShiftWork();
  },
  watch: {
    textSearch() {
      this.fetchShiftWork();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cài đặt ca làm việc', route: 'shift-work' },
      { title: 'Danh sách ca làm việc' },
    ]);
  },
  methods: {
    getData() {
      return {
        name: this.form.name,
        fromHour: this.form.fromHour,
        toHour: this.form.toHour,
      };
    },
    editItem(shiftwork) {
      this.form.name = shiftwork.name;
      this.form.fromHour = shiftwork.fromHour;
      this.form.toHour = shiftwork.toHour;
      this.action = 'update';
      this.id = shiftwork.id;
      this.modalTitle = 'Cập nhập ca làm việc';
    },
    fetchShiftWork: async function () {
      const param = {
        textSearch: this.textSearch,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('/shift-work/', paramQuery).then((response) => {
        this.listShiftWork = response.data.data;
      });
    },
    deleteItem: async function (item) {
      ApiService.setHeader();
      ApiService.delete('shift-work' + '/' + item.id)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchShiftWorkConfig();
            this.hideModal();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data ? response.data.message : 'Lỗi hệ thống !');
        });
    },
    handelBtnCreate() {
      this.action = 'create';
      this.modalTitle = 'Thêm ca làm việc';
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa ca làm việc!',
        text: 'Bạn có chắc muốn xóa ca làm việc này không  ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
      setTimeout(() => {
        this.fetchShiftWork();
      }, 1000);
    },
    hideModal() {
      this.$bvModal.hide('modal-setting-shiftWork');
    },
    onSubmit(event) {
      event.preventDefault();
      const data = this.getData();
      ApiService.setHeader();
      if (this.action == 'update') {
        ApiService.put('/shift-work/', { ...data, id: this.id })
          .then(({ data }) => {
            makeToastSuccess(data.message);
            setTimeout(() => {
              this.fetchShiftWork();
              this.hideModal();
            }, 1000);
          })
          .catch(({ response }) => {
            makeToastFaile(response.data ? response.data.message : 'Lỗi hệ thống !');
          });
      } else {
        ApiService.post('/shift-work/', data)
          .then(({ data }) => {
            makeToastSuccess(data.message);
            setTimeout(() => {
              this.fetchShiftWork();
              this.hideModal();
            }, 1000);
          })
          .catch(({ response }) => {
            makeToastFaile(response.data ? response.data.message : 'Lỗi hệ thống !');
          });
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.name = '';
      this.form.toHour = null;
      this.form.fromHour = null;
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  components: {
    KTCodePreview,
    Loader,
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.actionProClass {
  width: 7%;
}
.th-table {
  text-align: center;
  color: rgb(24, 28, 50);
}

</style>
